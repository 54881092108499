var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-8 mb-8 mb-md-5", attrs: { id: "shopping-cart" } },
    [
      _vm.categorySelected !== "pechavyHouse"
        ? _c("div", { staticClass: "cart-summary bg-info rounded px-4 py-5" }, [
            _vm.customerType === "pro"
              ? _c("div", { staticClass: "h6 border-bottom pb-3 mb-4" }, [
                  _vm._v("\n      Votre devis\n    ")
                ])
              : _c("div", { staticClass: "h6 border-bottom pb-3 mb-4" }, [
                  _c("img", {
                    staticClass: "mt-n2",
                    attrs: {
                      src: "/static/dist/images/icons/green-cart.png",
                      width: "21px"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("\n        Récapitulatif de votre commande\n      ")
                  ])
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "cart-summary-item mb-4" }, [
              _vm.categorySelected === "granules"
                ? _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        1. Type de granulé\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.categorySelected === "logs"
                ? _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        1. Choix du bois\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.customerType === "pro"
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.command.product.title) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.customerType !== "pro" && _vm.productSelected.id !== 21
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.command.product.title) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.customerType !== "pro" && _vm.productSelected.id === 21
                ? _c("div", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.command.product.title) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.customerType !== "pro"
              ? _c("div", { staticClass: "cart-summary-item mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        2. Conditionnement et quantité\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _vm.command.packaging
                        ? _c("div", [
                            _vm.packageSelected === "sac" &&
                            _vm.productSelected.category === 2
                              ? _c("div", [
                                  _vm._v(
                                    "\n            Lot de " +
                                      _vm._s(_vm.command.packaging) +
                                      " kg\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "palett" &&
                            _vm.productSelected.category === 2
                              ? _c("div", [
                                  _vm._v(
                                    "\n            Palette de " +
                                      _vm._s(_vm.command.product.sac) +
                                      " sacs\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "half-palett" &&
                            _vm.productSelected.category === 2 &&
                            _vm.productSelected.id !== 2 &&
                            _vm.productSelected.product_title !== "vente-unite"
                              ? _c("div", [
                                  _vm._v(
                                    "\n            1 demi-palette de 33 sacs\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "half-palett" &&
                            _vm.productSelected.category === 2 &&
                            _vm.productSelected.id === 2 &&
                            _vm.productSelected.product_title !== "vente-unite"
                              ? _c("div", [
                                  _vm._v(
                                    "\n            1 demi-palette de 28 sacs\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "sac" &&
                            _vm.productSelected.category === 5 &&
                            _vm.productSelected.product_title !== "vente-unite"
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.command.product.title) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "half-palett" &&
                            _vm.productSelected.category === 5 &&
                            _vm.productSelected.product_title !== "vente-unite"
                              ? _c("div", [
                                  _vm._v(
                                    "\n            1 demi-palette\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected !== "sac" &&
                            _vm.packageSelected !== "half-palett" &&
                            _vm.productSelected.category !== 2 &&
                            _vm.productSelected.id !== 21
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.command.packaging) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.productSelected.product_title === "vente-unite"
                              ? _c("div", [
                                  _vm._v("\n            A l'unité\n          ")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "sac" &&
                            _vm.productSelected.category !== 2 &&
                            _vm.productSelected.category !== 5 &&
                            _vm.productSelected.id !== 21
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.command.packaging) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "sac" &&
                            _vm.productSelected.id === 21
                              ? _c("div", [
                                  _vm._v("\n            1 sac\n          ")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected !== "sac" &&
                            _vm.productSelected.id === 21
                              ? _c("div", [
                                  _vm._v("\n            1 recharge\n          ")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "palett"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold text-primary"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("roundPrice")(
                                            _vm.command.product
                                              .price_tonne_pallet
                                          )
                                        ) +
                                        " € TTC / palette\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "half-palett"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold text-primary"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("roundPrice")(
                                            _vm.command.product.half_palett
                                          )
                                        ) +
                                        " € TTC / demi-palette\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.packageSelected === "sac"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold text-primary"
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("roundPrice")(
                                            _vm.command.product.price
                                          )
                                        ) +
                                        " € TTC / sac\n          "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.command.packaging
                        ? _c("div", [
                            _vm.packageSelected !== "sac" &&
                            _vm.command.product.category !== 5
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.command.quantity,
                                      expression: "command.quantity"
                                    }
                                  ],
                                  staticClass:
                                    "form-control cart-summary-quantity",
                                  attrs: {
                                    type: "number",
                                    step: "1",
                                    min: "0",
                                    oninput:
                                      "this.value=this.value.replace(/[^0-9]/g,'');",
                                    disabled: _vm.isInputDisabled
                                  },
                                  domProps: { value: _vm.command.quantity },
                                  on: {
                                    click: function($event) {
                                      return _vm.quantity()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.command,
                                        "quantity",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.command.quantity,
                                      expression: "command.quantity"
                                    }
                                  ],
                                  staticClass:
                                    "form-control cart-summary-quantity",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    step: "1",
                                    max: "60",
                                    oninput:
                                      "this.value=this.value.replace(/[^0-9]/g,'');",
                                    disabled: _vm.isInputDisabled
                                  },
                                  domProps: { value: _vm.command.quantity },
                                  on: {
                                    click: function($event) {
                                      return _vm.quantity()
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.command,
                                        "quantity",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro"
              ? _c("div", { staticClass: "cart-summary-item mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        3. Produit complémentaire\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.command.accessories.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.command.accessories, function(accessory) {
                          return _c(
                            "div",
                            {
                              key: accessory.id,
                              staticClass:
                                "d-flex align-items-start justify-content-between mb-2"
                            },
                            [
                              _c("div", [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(accessory.title) +
                                      "\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.customerType !== "pro"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-primary"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("roundPrice")(
                                                accessory.price
                                              )
                                            ) +
                                            " € / unité\n            "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: accessory.temp_number,
                                    expression: "accessory.temp_number"
                                  }
                                ],
                                staticClass:
                                  "form-control cart-summary-quantity",
                                attrs: {
                                  min: "0",
                                  step: "1",
                                  type: "number",
                                  oninput:
                                    "this.value=this.value.replace(/[^0-9]/g,'');",
                                  disabled: _vm.isInputDisabled
                                },
                                domProps: { value: accessory.temp_number },
                                on: {
                                  click: function($event) {
                                    return _vm.check_number(accessory)
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      accessory,
                                      "temp_number",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n        Pas de produit complémentaire\n      "
                        )
                      ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro"
              ? _c("div", { staticClass: "cart-summary-item mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        4. Livraison\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.command.delivery.type === "drive"
                    ? _c("div", [
                        _vm.command.cityDrive === "bordeaux"
                          ? _c("div", [
                              _vm._v("\n          Retrait au drive\n        ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.command.cityDrive === "agen"
                          ? _c("div", [
                              _vm._v("\n          Retrait au drive\n        ")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.command.delivery.type === "home"
                    ? _c("div", [
                        _vm.auth
                          ? _c("div", [
                              _vm._v("\n          " + _vm._s(_vm.user.adress1)),
                              _c("br"),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("capitalize")(_vm.user.city)) +
                                  " ( " +
                                  _vm._s(_vm.user.zipcode) +
                                  " )\n        "
                              )
                            ])
                          : _c("div", [
                              _vm._v("\n          Livraison -"),
                              _c("br"),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.command.delivery.city.name) +
                                  " (" +
                                  _vm._s(_vm.command.delivery.city.zipcode) +
                                  ")\n        "
                              )
                            ]),
                        _vm._v(" "),
                        !_vm.command.delivery.city.name
                          ? _c("div", [
                              _vm._v(
                                "\n          Aucune ville sélectionnée\n        "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro" && _vm.command.options.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "mb-2 text-secondary font-weight-bold" },
                      [_vm._v("\n        Option\n      ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.command.options, function(option) {
                      return _c("div", { staticClass: "mb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "align-items-start justify-content-between mb-2"
                          },
                          [
                            _vm.customerType !== "pro" &&
                            _vm.command.product.category === 4
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " + _vm._s(option.title)
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-primary"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("roundPrice")(option.price)
                                          ) +
                                          " € par stère\n            "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customerType !== "pro" &&
                            _vm.command.product.category !== 4
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " + _vm._s(option.title)
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-primary"
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm._f("roundPrice")(option.price)
                                          ) +
                                          " € par palette\n            "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.gift_command
              ? _c("div", { staticClass: "cart-summary-item mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2 text-secondary font-weight-bold" },
                    [_vm._v("\n        Votre cadeau\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.gift_matches
                    ? _c(
                        "div",
                        { staticClass: "text-secondary font-weight-bold" },
                        [
                          _vm._v(
                            "\n        Une boîte d'allume feux MAISON PECHAVY 100% naturel offerte\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.gift_apron
                    ? _c(
                        "div",
                        { staticClass: "text-secondary font-weight-bold" },
                        [
                          _vm._v(
                            "\n        Un tablier MAISON PECHAVY offert\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro"
              ? _c("div", { staticClass: "border-top pt-4 mb-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("div", [_vm._v("\n          Sous-total\n        ")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("comma")(_vm.total_price)) +
                            " €\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("div", [_vm._v("\n          Livraison\n        ")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.get_delivery_price) +
                            " €\n        "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between font-weight-bold text-secondary"
                    },
                    [
                      _c("div", [_vm._v("\n          Total\n        ")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("comma")(_vm.final_price)) +
                            " €\n        "
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.categorySelected !== "pechavyHouse" &&
            _vm.customerType === "pro"
              ? _c("div", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.socialname,
                          expression: "socialname"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Nom",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.socialname },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.socialname = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.command.email,
                          expression: "command.email"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        placeholder: "Email",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.command.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.command, "email", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.email_error.length > 0
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.email_error) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "tel",
                        placeholder: "Téléphone",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.phone_error.length > 0
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.phone_error) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.volume,
                          expression: "volume"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Volume en tonne",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.volume },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.volume = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.volume_error.length > 0
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.volume_error) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.zipcode_input,
                          expression: "zipcode_input"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Code postal",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.zipcode_input },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.zipcode_input = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.city_input,
                          expression: "city_input"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Ville",
                        disabled: _vm.loader
                      },
                      domProps: { value: _vm.city_input },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.city_input = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro"
              ? _c("div", [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.checked,
                              expression: "checked"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            id: "checked",
                            type: "checkbox",
                            disabled: _vm.loader
                          },
                          domProps: {
                            checked: Array.isArray(_vm.checked)
                              ? _vm._i(_vm.checked, null) > -1
                              : _vm.checked
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.checked,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checked = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checked = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm._m(0)
                      ]
                    ),
                    _vm._v(" "),
                    _vm.cgvError
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n          Veuillez accepter les conditions générales de ventes avant de poursuivre l'opération.\n        "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.auth &&
            _vm.checkCity === false &&
            _vm.command.delivery.type === "home" &&
            _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Votre ville n'est pas livrable par nos service, veuillez opter pour un retrait au drive\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.productError
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Avant de commande veuillez sélectionner un de nos produits (étape 1)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.packagingError && _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Avant de commander veuillez sélectionner le conditionnement souhaité (étape 2)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deliveryPackagingError &&
            _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Seul le conditionnement de type palette est disponible en livraison, veuillez changer le type de conditionnement (étape 2) ou de mode de livraison (étape 4)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.granuleDriveError && _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [_vm._m(1)])
              : _vm._e(),
            _vm._v(" "),
            _vm.logDriveError && _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Les produits Bois bûches NF 30cm et NF 50cm ne sont pas disponible au drive de Bordeaux, veuillez opter pour le bois bûche densifiée ou opter pour le retrait au drive d'Agen (étape 4)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.accessoriesDriveError &&
            _vm.pechavy_drive_error !== false &&
            _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Ces accessoires ne sont pas disponible au drive de Bordeaux:\n      "
                    )
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    _vm._l(_vm.pechavy_drive_error, function(item) {
                      return _c(
                        "li",
                        { key: item.id, staticClass: "font-weight-bold" },
                        [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("strong", [
                    _vm._v(
                      "\n        Veuillez les supprimer de votre commande ou opter pour le retrait au drive d'Agen\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deliveryError && _vm.categorySelected !== "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Avant de commander veuillez sélectionner un mode de livraison (étape 4)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deliveryError && _vm.categorySelected === "pechavyHouse"
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _c("strong", [
                    _vm._v(
                      "\n        Veuillez sélectionner un mode de livraison (étape 2)\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.message
              ? _c("p", { staticClass: "text-secondary font-weight-bold" }, [
                  _vm._v("\n      " + _vm._s(_vm.message) + "\n    ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro" &&
            _vm.categorySelected !== "pechavyHouse"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    attrs: { disabled: _vm.loader },
                    on: {
                      click: function($event) {
                        return _vm.doPayment()
                      }
                    }
                  },
                  [
                    _vm.loader
                      ? _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" }
                        })
                      : !_vm.loader && !_vm.auth
                      ? _c("span", [_vm._v("\n        Je commande\n      ")])
                      : _c("span", [
                          _vm._v("\n        Je confirme la commande\n      ")
                        ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType !== "pro" &&
            _vm.categorySelected === "pechavyHouse"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    attrs: { disabled: _vm.loader },
                    on: {
                      click: function($event) {
                        return _vm.doPayment()
                      }
                    }
                  },
                  [
                    _vm.loader
                      ? _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" }
                        })
                      : _c("span", [_vm._v("\n        Je commande\n      ")])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.customerType === "pro"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    attrs: { disabled: _vm.loader },
                    on: {
                      click: function($event) {
                        return _vm.devis()
                      }
                    }
                  },
                  [
                    _vm.loader
                      ? _c("span", {
                          staticClass: "spinner-border spinner-border-sm",
                          attrs: { role: "status", "aria-hidden": "true" }
                        })
                      : _c("span", [
                          _vm._v("\n        Je demande un devis\n      ")
                        ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "custom-control-label", attrs: { for: "checked" } },
      [
        _vm._v(
          "\n            En cochant je confirme avoir pris connaissances des "
        ),
        _c(
          "a",
          { attrs: { target: "_blank", href: "/static/cgv-pechavy.pdf" } },
          [_vm._v("conditions générales de ventes")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v(
        "\n        Les granulés de bois GRASSASA ne sont pas disponibles au drive de Bordeaux, veuillez changer de granulés de bois (étape 2) ou opter pour le retrait au drive d'Agen "
      ),
      _c("br"),
      _vm._v("(étape 4)\n      ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }