<template src ="../../templates/home.html" />
<script>
import axios from 'axios'
import Bus from '../Bus.js'
import OrderStep from './orderStep.vue'
import FormPayment from './formPayment.vue'
import ShoppingCart from './shoppingCart.vue'
import HeaderCommand from './headerCommand.vue'
import AccountCreation from './accountCreation.vue'
import AccountConnexion from './accountConnexion.vue'
export default {
  name: 'home',
  components: {
    AccountConnexion,
    AccountCreation,
    HeaderCommand,
    ShoppingCart,
    FormPayment,
    OrderStep,
  },
  data: () => ({
    accountConnexion: false,
    accountCreation: false,
    detailProduct: false,
    paybox: '',
    discount: false,
    index: 0,
    paymentConfirmation: false,
    auth: '',
    customerType: 'particular',
    orders: '',
    giftLimit: 0,
    itemLimit: 0,
    items: [],
    distance: 0,
    itemQuantity: 0,
    deliveryGrid: [],
    command: {
      id: '',
      product: {},
      name: '',
      packaging: '',
      delivery: {
        city: {},
        price: 0,
        type: '',
      },
      options: [],
      total: 0,
      email:'',
      cityDrive:'',
      weight: 0,
      city: {},
      status: 'en cours',
      quantity: 1,
      packageStorage:'',
      accessories: []
    },
    urlCategory: '',
    user: "",
    categorySelected: '',
    deliveryPrice: 0,
    productSelected: '',
    checkCity: '',
    deliverySelected: '',
    halfPackageBag:'',
    packageSelected:'',
    accessorySelected: [],
    products: [],
    paymentLauncher: false,
    productInfo: '',
    lastProduct: ''
  }),
  async created () {
    try {
      let response = await axios({
        method: 'get',
        url: '/category_check/'
      })
      let catSplit = response.data.category.split('/')
      this.categorySelected = catSplit.length > 1 ? catSplit[0] : response.data.category
      switch (this.categorySelected) {
        case 'granules':
          this.urlCategory = '/simple-product/rest/granule-list/'
          break
        case 'logs':
          this.urlCategory = '/simple-product/rest/log-list/'
          break
        case 'pechavyHouse' || 'maison-pechavy':
          this.urlCategory = '/simple-product/rest/accessory-list/'
          this.command.delivery.type = 'home'
          break
        default: break
      }
      response = await axios({
        method: 'get',
        url: this.urlCategory
      })
      this.products = response.data
      if(localStorage.getItem('lastOrder')) {
        this.last_order()
      }
      response = await axios({
        url: '/simple-product/rest/delivery-list/',
        method: 'get'
      })
      this.deliveryGrid = response.data
      const orderAborted = JSON.parse(localStorage.getItem('orderAborted'))
      const command = JSON.parse(localStorage.getItem('command'))
      if (orderAborted && command !== null) {
        this.command = command
      }
    } catch (error) {
      console.log(error)
    }
  },
        updated() {
            axios({
                method: 'get',
                url:'/authentification/auth_check/'
            }).then(response => {
                this.auth = response.data.auth
                if(this.auth == true) {
                    if(this.user == "") {
                        this.user = response.data.user[0]
                        this.orders = JSON.parse(response.data.order)
                        this.items = response.data.item
                    }
                }
                Bus.$emit('check-delivery', (product) => {
                })
            }).then(getitems => {
                if(this.productSelected && this.auth == true) {
                axios({
                    method: 'post',
                    url: '/authentification/gift/',
                    data: this.productSelected.id
                }).then(response2=> {
                    var itemNumber = 0;
                    for (var i = 0; i < response2.data.item.length; i++) {
                        itemNumber += response2.data.item[i].quantity;
                    }
                    for(var limit = 10; itemNumber > limit; limit += 10) {
                        }
                    this.itemQuantity = itemNumber;
                    this.itemLimit = limit;
                                })
            }})
            .catch(error => {
            });
            this.$on('user-update', (user) => {
                this.user = user
            })
            this.$on('update-product', (product) => {
                for(var i = 0; i < this.products.length; i++) {
                    if(this.products[i].temp_number == 0) {
                        this.products[i].selected = false;
                        this.products[i].temp_number = 1
                    }
                }
            })
            localStorage.setItem('command', JSON.stringify(this.command))

             if(this.command.packaging !== "") {
                 console.log('ty')
                if((this.productSelected.title == 'Pack 5 bûches') && (this.packageSelected == 'palett')) {
                    this.command.packaging = 'palette de 128 packs'
                }else  if((this.productSelected.product_title == 'Bois bûche') && (this.packageSelected == 'palett')) {
                    this.command.packaging = '1 stère'
                }else  if((this.productSelected.product_title == 'Bois bûche') && (this.packageSelected == 'sac')) {
                    this.command.packaging = '1 demi-stère'
                }
                else if(this.command.packageStorage == 'palett') {
                    this.command.packaging = 'Palette de ' + this.productSelected.sac + ' sacs'
                }else if(this.command.packageStorage == 'sac') {
                    this.command.packaging = '6 sacs de ' + parseInt(this.productSelected.weight)
                }else if(this.command.packageStorage == 'half-palett') {
                    this.halfPackageBag = Math.ceil(this.productSelected.sac/2)
                    this.command.packaging = 'demi-palette de ' + this.halfPackageBag + ' sacs'
                }
            }
        },

        methods: {
            updateCityDrive(selectedDrive) {
                this.command.cityDrive = selectedDrive;
                console.log('Updated cityDrive:', selectedDrive);
            },
            handleScrollCard: _.debounce(function() {
                if(document.getElementById('shopMissing')) {
                    document.getElementById('shopMissing').style.marginTop = (window.scrollY) + 'px'
                    document.getElementById('shopMissing')
                }
            },50),
            detail_product: function(product) {
                this.productInfo = product;
                this.detailProduct = true;
                setTimeout(function(){
                document.getElementById('shopMissing').style.marginTop = (window.scrollY) + 'px';
                }, 100);

            },
            select_drive: function(item) {
                this.command.cityDrive = item
            },
            last_order: function() {
                var orderId = this.items.map(e=>{
                    return e.product
                })
                for (var i = 0; i< this.products.length; i++) {
                    if(orderId.indexOf(this.products[i].id) !== -1) {
                        this.products[i].selected = true;
                    }else {
                        this.products[i].selected = false
                    }
                    for (var t = 0; t < this.items.length; t++) {
                        if(this.items[t].product == this.products[i].id){
                            if((this.products[i].product_title !== ('accessoire')) && (this.products[i].product_title !== ('options'))){
                                this.lastProduct = this.products[i];
                                this.productSelected = this.products[i]
                                this.command.product = this.products[i] ;
                                this.command.quantity = this.items[t].quantity;
                            }else if(this.products[i].product_title == 'accessoire') {
                                this.products[i].temp_number = this.items[t].quantity;
                                this.command.accessories.push(this.products[i])
                            }else if(this.products[i].product_title == 'options'){
                                this.command.options.push(this.products[i])
                            }
                        }
                    }
                }
                this.command.delivery.price = 85;
                if(this.orders.fields.delivery_mode == 'home') {
                    this.command.delivery.type = 'home'
                }else if(this.orders.fields.delivery_home == 'drive') {
                    this.command.delivery.type = 'drive'
                }else {
                    this.command.delivery.type = ''
                }
                this.command.packaging = this.orders.fields.packaging;
                if(localStorage.getItem('lastOrder')) {
                    localStorage.removeItem('lastOrder')
                }
                this.packageSelected = this.orders.fields.package_info;
                this.$emit('user-infos')

            },

            close(type) {
                if(type == 'product') {
                    this.detailProduct = false
                }
                if(type == 'paybox') {
                    this.paymentConfirmation = false;
                }
                if(type == 'connexion') {
                    this.accountConnexion = false;
                }
                if(type == 'creation') {
                    this.accountCreation = false;
                }
            },
            // Mise à jour de la quantité du product dans le local storage
            quantity_storage_update: function() {
                localStorage.setItem('command', JSON.stringify(this.command))
            },
            //Succés de la connexion à un compte + lancement du paiement
            connexion_success: function(item) {
                this.accountConnexion = false
                this.user = item[0]
                this.paymentLauncher = true
                this.auth = true
            },
            //Succés d'une création de compte + lancement du paiement
            creation_success: function(item) {
                this.accountCreation = false
                this.user = item[0]
                this.auth = true
                this.paymentLauncher = true
            },// show creation component
            creation: function() {
                this.accountConnexion = false
                this.accountCreation = true
                window.scrollTo(0,0)
            }, // show connexion component
            connexion: function() {
                this.accountCreation = false
                this.accountConnexion = true
                window.scrollTo(0,0)
            },
            product: function(product) {
                this.productSelected = product
                this.command.name = product.product_title + ' ' + product.title
                this.command.product = this.productSelected
            },
            package_selected: function(packaging) {
                this.packageSelected = packaging
                this.command.packageStorage = packaging
                if((this.productSelected.category == 5) && (this.packageSelected == 'palett')) {
                    return this.command.packaging = 'palette de 128 packs'
                }else  if((this.productSelected.product_title == 'Bois bûche') && (this.packageSelected == 'palett')) {
                    return this.command.packaging = '1 stère'
                }else  if((this.productSelected.product_title == 'Bois bûche') && (this.packageSelected == 'sac')) {
                    return this.command.packaging = '1 demi-stère'
                }
                else if(packaging == 'palett' && this.productSelected.category !== 5) {
                    return this.command.packaging = 'Palette de ' + this.productSelected.sac + ' sacs'
                }else if(packaging == 'sac') {
                    return this.command.packaging = '6 sacs de ' + parseInt(this.productSelected.weight)
                }else if(packaging == 'half-palett') {
                    this.halfPackageBag = Math.ceil(this.productSelected.sac/2)
                    return this.command.packaging = 'demi-palette de ' + this.halfPackageBag + ' sacs'
                }
            },
            quantity_selected: function(quantity) {
                this.command.quantity = quantity
            },
            accessory_selected: function(accessories) {
                this.command.accessories = accessories
                localStorage.setItem('command', JSON.stringify(this.command))
            },
            option_selected: function(option) {
                this.command.options = option
            },
            delivery_choosen: function(deliveryChoosen) {
                this.command.delivery.type = deliveryChoosen
            },
            get_delivery: function( city, delivery, check) {
                this.command.delivery.city = city;
                this.checkCity = check
                if(typeof delivery!== 'undefined') {
                    this.test2 = delivery
                    this.command.delivery.type = delivery;
                }
            },
            form_paybox: function(item) {
                this.paymentLauncher = false
                this.paybox = item
                $('#modal-payment').modal('show')
            },
            form_discount: function(item) {
                this.discount = item
            },
        }
    }
</script>