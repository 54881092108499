var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-8 mb-3 mb-md-5" }, [
    _vm.categorySelected === "pechavyHouse"
      ? _c("p", [
          _vm._v(
            "\n\t\tMAISON PECHAVY a été pensé pour célébrer le centenaire de l'entreprise familiale. "
          ),
          _c("br"),
          _vm._v(
            "\n    Depuis 1919, notre histoire s'écrit autour du confort et de la chaleur du foyer. "
          ),
          _c("br"),
          _vm._v(
            "\n    Sensible à l'art de vivre, nous avons crée les produits MAISON PECHAVY afin d'ajouter à votre intérieur une pointe d'élégance et de raffinement. Tous nos produits sont éco-responsables et 100% naturels.\n    Vous découvrirez régulièrement de nouveaux produits sur cette page et il suffira d'un simple \"clic\" pour les recevoir chez vous par colissimo.  Frais de livraison offerts à partir de 30 euros d'achats."
          ),
          _c("br"),
          _vm._v(
            "\n\t\tMAISON PECHAVY est une marque PECHAVY ENERGIE BOIS.\n\t"
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "accordion cart-steps", attrs: { id: "steps" } }, [
      _vm.categorySelected !== "pechavyHouse"
        ? _c("div", { staticClass: "card cart-step" }, [
            _c(
              "div",
              { staticClass: "card-header bg-white", attrs: { id: "step-1" } },
              [
                _c("small", { staticClass: "text-primary font-weight-bold" }, [
                  _vm._v("\n\t\t\t\t\tEtape 1 sur 3\n\t\t\t\t")
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "mb-0" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-link btn-block text-left text-secondary px-0",
                      attrs: {
                        type: "button",
                        "data-toggle": "collapse",
                        "data-target": "#step-content-1",
                        "aria-expanded": "true",
                        "aria-controls": "step-content-1"
                      }
                    },
                    [
                      _vm._v("\n            Choix du produit\n            "),
                      _vm.product
                        ? _c("span", { staticClass: "ml-3" }, [
                            _c("img", {
                              staticClass: "mt-n1",
                              attrs: {
                                src: "/static/dist/images/icons/check.png",
                                width: "18px"
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse show",
                attrs: {
                  id: "step-content-1",
                  "aria-labelledby": "step-1",
                  "data-parent": "#steps"
                }
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _vm.categorySelected === "granules" &&
                  _vm.customerType !== "pro"
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tCliquez sur le sac de granulés de bois souhaité\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.categorySelected === "granules" &&
                  _vm.customerType === "pro"
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tCliquez sur le granulé de bois en vrac\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.categorySelected === "logs"
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tCliquez sur le produit souhaité\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      attrs: {
                        itemscope: "",
                        itemtype: "http://schema.org/ItemList"
                      }
                    },
                    [
                      _vm._l(_vm.products, function(product) {
                        return _vm.customerType !== "pro" &&
                          product.category !== 1 &&
                          product.category !== 3 &&
                          product.category !== 7 &&
                          product.category !== 6
                          ? _c(
                              "div",
                              {
                                key: product.id,
                                staticClass: "col-md-6 col-lg-4 mb-4"
                              },
                              [
                                _c(
                                  "article",
                                  {
                                    staticClass:
                                      "cart-product bg-light rounded p-3 h-100",
                                    class: { selected: product.selected },
                                    attrs: {
                                      itemprop: "itemListElement",
                                      itemscope: "",
                                      itemtype: "http://schema.org/Product"
                                    },
                                    on: {
                                      click: function($event) {
                                        product.number !== 0 &&
                                          _vm.selectProduct(product)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center mb-3" },
                                      [
                                        product.thumbnailImage
                                          ? _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/" +
                                                  product.thumbnailImage,
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                          : _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/img/products/1.png",
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "cart-product-title text-center",
                                        attrs: { itemprop: "name" }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(product.title) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    product.description.length > 0
                                      ? _c(
                                          "p",
                                          { staticClass: "text-center" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(product.description) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.category === 2
                                      ? _c(
                                          "small",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.start_price
                                                        ) + "€"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" / Sac")
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.category === 4 &&
                                    product.id !== 21 &&
                                    product.slug !== "ballot-40cm" &&
                                    product.slug !== "ballot-30cm"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.price_tonne
                                                        ) + "€"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" / stère")
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.category === 4 &&
                                    product.id !== 21 &&
                                    (product.slug === "ballot-40cm" ||
                                      product.slug === "ballot-30cm")
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          product.price_tonne
                                                        ) + "€"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" / ballot")
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.category === 4 &&
                                    product.id !== 21 &&
                                    product.product_title === "Accessoire"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(product.price) +
                                                          "€"
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.category === 5
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _vm._v("A partir de "),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [_vm._v("3.9€")]
                                                  ),
                                                  _vm._v(" / l'unité")
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro" &&
                                    product.id === 21
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            product.number !== 0
                                              ? _c("div", [
                                                  _vm._v("A partir de "),
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        itemprop: "price"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("roundPrice")(
                                                            product.price
                                                          )
                                                        ) + "€"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" / l'unité")
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    "Commandes uniquement par téléphone"
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.products, function(product) {
                        return product.category === 3 &&
                          _vm.customerType === "pro"
                          ? _c(
                              "div",
                              {
                                key: product.id,
                                staticClass: "col-md-6 col-lg-4 mb-4"
                              },
                              [
                                _c(
                                  "article",
                                  {
                                    staticClass:
                                      "cart-product bg-light rounded p-3 h-100",
                                    class: { selected: product.selected },
                                    attrs: {
                                      itemprop: "itemListElement",
                                      itemscope: "",
                                      itemtype: "http://schema.org/Product"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectProduct(product)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center mb-3" },
                                      [
                                        product.thumbnailImage
                                          ? _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/" +
                                                  product.thumbnailImage,
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                          : _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/img/products/1.png",
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "cart-product-title text-center",
                                        attrs: { itemprop: "name" }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(product.title) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    product.description.length > 0
                                      ? _c(
                                          "p",
                                          { staticClass: "text-center" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(product.description) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { attrs: { itemprop: "price" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("roundPrice")(
                                                      (1000 / product.weight) *
                                                        product.price
                                                    )
                                                  ) + "€"
                                                )
                                              ]
                                            ),
                                            _vm._v(" / Tonne\n\t\t\t\t\t\t\t\t")
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.order_Mpechavy, function(product) {
                        return product.category === 7 &&
                          _vm.categorySelected === "pechavyHouse" &&
                          _vm.customerType !== "pro"
                          ? _c(
                              "div",
                              {
                                key: product.id,
                                staticClass: "col-md-6 col-lg-4"
                              },
                              [
                                _c(
                                  "article",
                                  {
                                    staticClass:
                                      "cart-product bg-light rounded p-3 h-100",
                                    class: { blueSelected: product.selected },
                                    attrs: {
                                      itemscope: "",
                                      itemtype: "http://schema.org/Product"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectAccessory(product)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center mb-3" },
                                      [
                                        product.thumbnailImage
                                          ? _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/" +
                                                  product.thumbnailImage,
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                          : _c("img", {
                                              attrs: {
                                                itemprop: "image",
                                                src:
                                                  "/static/img/products/1.png",
                                                alt: "product.title",
                                                height: "96px"
                                              }
                                            })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "cart-product-title text-center",
                                        attrs: { itemprop: "name" }
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(product.title)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm._f("upper")(
                                                product.product_title
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.customerType !== "pro"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-block text-primary text-center font-weight-bold",
                                            attrs: {
                                              itemprop: "offers",
                                              itemscope: "",
                                              itemtype:
                                                "http://schema.org/Offer"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { attrs: { itemprop: "price" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("roundPrice")(
                                                      product.price
                                                    )
                                                  ) + "€"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-center",
                                        on: {
                                          click: function($event) {
                                            return _vm.detail(product)
                                          }
                                        }
                                      },
                                      [_c("strong", [_vm._v("En savoir plus")])]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.categorySelected === "granules" &&
                  _vm.customerType !== "pro"
                    ? _c("div", { staticClass: "row" }, [_vm._m(0)])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 d-none" }, [
                      _vm.categorySelected === "granules" &&
                      _vm.customerType !== "pro"
                        ? _c("a", { attrs: { href: "/granules-sac" } }, [
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/info.png",
                                width: "16px"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold mx-3" },
                              [
                                _vm._v(
                                  "\n                  Plus d'infos sur nos granulés\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/arrow.png",
                                width: "16px"
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.categorySelected === "granules" &&
                      _vm.customerType === "pro"
                        ? _c("a", { attrs: { href: "/granules-vrac" } }, [
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/info.png",
                                width: "16px"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold mx-3" },
                              [
                                _vm._v(
                                  "\n                  Plus d'infos sur nos granulés\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/arrow.png",
                                width: "16px"
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.categorySelected === "logs"
                        ? _c("a", { attrs: { href: "/bois-buche" } }, [
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/info.png",
                                width: "16px"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "font-weight-bold mx-3" },
                              [
                                _vm._v(
                                  "\n                  Plus d'infos sur notre bois bûches\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/arrow.png",
                                width: "16px"
                              }
                            })
                          ])
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.customerType !== "pro" &&
      _vm.categorySelected !== "pechavyHouse" &&
      _vm.product.number !== 0
        ? _c("div", { staticClass: "card cart-step" }, [
            _c(
              "div",
              { staticClass: "card-header bg-white", attrs: { id: "step-2" } },
              [
                _c(
                  "small",
                  {
                    staticClass: "text-primary font-weight-bold",
                    class: { "text-muted": !_vm.product }
                  },
                  [_vm._v("\n\t\t\t\t\tEtape 2 sur 3\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "mb-0" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-link btn-block text-left text-secondary px-0",
                      attrs: {
                        type: "button",
                        "data-toggle": "collapse",
                        "data-target": "#step-content-2",
                        "aria-expanded": "true",
                        "aria-controls": "step-content-2",
                        disabled: !_vm.product
                      }
                    },
                    [
                      _vm._v("\n            Conditionnement\n            "),
                      _vm.packaging
                        ? _c("span", { staticClass: "ml-3" }, [
                            _c("img", {
                              staticClass: "mt-n1",
                              attrs: {
                                src: "/static/dist/images/icons/check.png",
                                width: "18px"
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse",
                attrs: {
                  id: "step-content-2",
                  "aria-labelledby": "step-2",
                  "data-parent": "#steps"
                }
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _vm.product && _vm.categorySelected === "granules"
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n            Choisissez le conditionnement souhaité pour vos granulés de bois\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product && _vm.categorySelected === "logs"
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          "\n            Choisissez le conditionnement souhaité pour vos Bois bûches\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _vm.product.slug !== "ballot-40cm" &&
                    _vm.product.slug !== "ballot-30cm"
                      ? _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4 mb-3 mb-md-0" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cart-packaging bg-light rounded p-3 h-100",
                                class: { selected: _vm.packaging === "sac" },
                                on: {
                                  click: function($event) {
                                    return _vm.selectPackaging("sac")
                                  }
                                }
                              },
                              [
                                _vm.product.product_title === "Bûche compressée"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-secondary text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.product.title) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.product_title === "Bois bûche" &&
                                _vm.product.id !== 21 &&
                                _vm.product.slug !== "ballot-40cm" &&
                                _vm.product.slug !== "ballot-30cm"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-secondary text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Le demi-stère\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.product_title === "Bois bûche" &&
                                _vm.product.id === 21
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold text-secondary text-center"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Le sac\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.product_title !== "Bois bûche" &&
                                _vm.product.product_title !==
                                  "Bûche compressée" &&
                                _vm.product.is_discount === false
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-secondary font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Lot de 6 sacs de " +
                                              _vm._s(
                                                _vm._f("intPrice")(
                                                  _vm.product.weight
                                                )
                                              ) +
                                              "kg\n                  "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v("\n\t\t10 lots achetés "),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  => 1 lot offert\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.product_title !== "Bois bûche" &&
                                _vm.product.product_title !==
                                  "Bûche compressée" &&
                                _vm.product.is_discount === true
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center text-secondary font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Lot de 6 sacs de " +
                                            _vm._s(
                                              _vm._f("intPrice")(
                                                _vm.product.weight
                                              )
                                            ) +
                                            " kg\n                "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.categorySelected === "granules" ||
                    _vm.product.category === 5
                      ? _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4 mb-3 mb-md-0" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cart-packaging bg-light rounded p-3 h-100",
                                class: {
                                  selected: _vm.packaging === "half-palett"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectPackaging("half-palett")
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center font-weight-bold text-secondary"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Demi-palette\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.product.category === 5
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                  60 packs\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.id !== 2 &&
                                _vm.product.category === 2
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                  33 sacs\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.product.id === 2 &&
                                _vm.product.category === 2
                                  ? _c("div", { staticClass: "text-center" }, [
                                      _vm._v(
                                        "\n                  28 sacs\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-lg-4 mb-3 mb-md-0" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "cart-packaging bg-light rounded p-3 h-100",
                            class: { selected: _vm.packaging === "palett" },
                            on: {
                              click: function($event) {
                                return _vm.selectPackaging("palett")
                              }
                            }
                          },
                          [
                            _vm.product.id === 21
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Recharge\n                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.title === "Pack 5 bûches"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Palette de 128 packs\n                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.product_title === "Bois bûche" &&
                            _vm.product.id !== 21 &&
                            _vm.product.slug !== "ballot-40cm" &&
                            _vm.product.slug !== "ballot-30cm"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Le stère\n                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.product_title === "Bois bûche" &&
                            _vm.product.id !== 21 &&
                            (_vm.product.slug === "ballot-40cm" ||
                              _vm.product.slug === "ballot-30cm")
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold text-secondary text-center"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Le ballot\n                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.product_title !== "Bois bûche" &&
                            _vm.product.title !== "Pack 5 bûches"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-secondary font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Palette de " +
                                        _vm._s(_vm.product.sac) +
                                        " sacs\n                "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.product.product_title !== "Bois bûche"
                              ? _c("div", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.product.palett) +
                                      "\n                "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.customerType !== "pro" &&
    _vm.categorySelected !== "pechavyHouse" &&
    _vm.product.number !== 0
      ? _c("div", { staticClass: "card cart-step d-none" }, [
          _c(
            "div",
            { staticClass: "card-header bg-white", attrs: { id: "step-X" } },
            [
              _c(
                "small",
                {
                  staticClass: "text-primary font-weight-bold",
                  class: { "text-muted": !_vm.packaging }
                },
                [_vm._v("\n        Etape 3 sur 4\n      ")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "mb-0" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-link btn-block text-left text-secondary px-0",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#step-content-X",
                      "aria-expanded": "true",
                      "aria-controls": "step-content-X",
                      disabled: !_vm.packaging
                    }
                  },
                  [
                    _vm._v("\n          Un peu plus de confort ?\n          "),
                    _vm.selectedAccessories.length > 0 || _vm.emptyAccessories
                      ? _c("span", { staticClass: "ml-3" }, [
                          _c("img", {
                            staticClass: "mt-n1",
                            attrs: {
                              src: "/static/dist/images/icons/check.png",
                              width: "18px"
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse",
              attrs: {
                id: "step-content-X",
                "aria-labelledby": "step-X",
                "data-parent": "#steps"
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v("\n          Accessoires Maison Pechavy\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.accessories, function(accessory) {
                    return _c(
                      "div",
                      {
                        key: accessory.id,
                        staticClass: "col-md-6 col-lg-4 mb-4"
                      },
                      [
                        accessory.category === 1 &&
                        _vm.categorySelected !== "granules"
                          ? _c(
                              "article",
                              {
                                staticClass:
                                  "cart-accessory bg-light rounded p-2 h-100",
                                class: { selected: accessory.selected },
                                attrs: {
                                  itemprop: "itemListElement",
                                  itemscope: "",
                                  itemtype: "http://schema.org/Product"
                                }
                              },
                              [
                                _c("div", { staticClass: "text-center mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "cart-accessory-details" },
                                    [
                                      accessory.thumbnailImage
                                        ? _c("img", {
                                            staticClass: "rounded",
                                            attrs: {
                                              itemprop: "image",
                                              src:
                                                "/static/" +
                                                accessory.thumbnailImage,
                                              alt: accessory.title,
                                              width: "100%"
                                            }
                                          })
                                        : _c("img", {
                                            staticClass: "rounded",
                                            attrs: {
                                              itemprop: "image",
                                              src: "/static/img/products/1.png",
                                              alt: accessory.title,
                                              width: "100%"
                                            }
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/produits/detail/" +
                                              accessory.slug +
                                              "/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  En savoir plus\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "cart-accessory-title text-center",
                                    attrs: { itemprop: "name" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(accessory.title) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-secondary text-center font-weight-bold",
                                    attrs: {
                                      itemprop: "offers",
                                      itemscope: "",
                                      itemtype: "http://schema.org/Offer"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { itemprop: "price" } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("roundPrice")(
                                                accessory.price
                                              )
                                            ) +
                                            "€\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link btn-block font-weight-bold",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectAccessory(accessory)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Ajouter au panier\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        accessory.category === 7
                          ? _c(
                              "article",
                              {
                                staticClass:
                                  "cart-accessory bg-light rounded p-2 h-100",
                                class: { selected: accessory.selected },
                                attrs: {
                                  itemprop: "itemListElement",
                                  itemscope: "",
                                  itemtype: "http://schema.org/Product"
                                }
                              },
                              [
                                _c("div", { staticClass: "text-center mb-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "cart-accessory-details" },
                                    [
                                      accessory.thumbnailImage
                                        ? _c("img", {
                                            staticClass: "rounded",
                                            attrs: {
                                              itemprop: "image",
                                              src:
                                                "/static/" +
                                                accessory.thumbnailImage,
                                              alt: accessory.title,
                                              width: "100%"
                                            }
                                          })
                                        : _c("img", {
                                            staticClass: "rounded",
                                            attrs: {
                                              itemprop: "image",
                                              src: "/static/img/products/1.png",
                                              alt: accessory.title,
                                              width: "100%"
                                            }
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/produits/detail/" +
                                              accessory.slug +
                                              "/"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  En savoir plus\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "cart-accessory-title text-center",
                                    attrs: { itemprop: "name" }
                                  },
                                  [
                                    _c("div", { staticClass: "mb-2" }, [
                                      _vm._v(_vm._s(accessory.title))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "text-primary text-uppercase"
                                      },
                                      [_vm._v(_vm._s(accessory.product_title))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-secondary text-center font-weight-bold",
                                    attrs: {
                                      itemprop: "offers",
                                      itemscope: "",
                                      itemtype: "http://schema.org/Offer"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { itemprop: "price" } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("roundPrice")(
                                                accessory.price
                                              )
                                            ) +
                                            "€\n                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-link btn-block font-weight-bold",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectAccessory(accessory)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Ajouter au panier\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between"
                  },
                  [
                    !_vm.allAccessories
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.showAllAccessories()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n              Voir tous les produits\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: "/static/dist/images/icons/arrow-down.png"
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary ml-1",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.hideAllAccessories()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            Je ne souhaite pas ajouter de produits\n          "
                        )
                      ]
                    )
                  ]
                )
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.customerType !== "pro" &&
    _vm.categorySelected !== "pechavyHouse" &&
    _vm.product.number !== 0
      ? _c("div", { staticClass: "card cart-step" }, [
          _c(
            "div",
            { staticClass: "card-header bg-white", attrs: { id: "step-3" } },
            [
              _c(
                "small",
                {
                  staticClass: "text-primary font-weight-bold",
                  class: { "text-muted": !_vm.packaging }
                },
                [_vm._v("\n        Etape 3 sur 3\n      ")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "mb-0" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-link btn-block text-left text-secondary px-0",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#step-content-3",
                      "aria-expanded": "true",
                      "aria-controls": "step-content-3",
                      disabled: !_vm.packaging
                    }
                  },
                  [_vm._v("\n          Mode de livraison\n          ")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse",
              attrs: {
                id: "step-content-3",
                "aria-labelledby": "step-3",
                "data-parent": "#steps"
              }
            },
            [
              _c("div", { staticClass: "card-body" }, [
                _c("p", { staticClass: "text-muted" }, [
                  _vm._v(
                    "\n          Choisissez votre mode de livraison\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 mb-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "cart-delivery rounded p-4 h-100",
                        class: { selected: _vm.delivery_mode === "home" },
                        attrs: {
                          disabled:
                            (_vm.packaging !== "palett" ||
                              _vm.checkCityDelivery === false ||
                              _vm.product.id === 21) &&
                            _vm.categorySelected !== "pechavyHouse"
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectDelivery("home")
                          }
                        }
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-secondary font-weight-bold mb-3"
                          },
                          [
                            _vm._v(
                              "\n                Livraison à domicile\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                Au plus près du lieu de stockage\n              "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.checkCityDelivery == false
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("votre ville n'est pas livrable"),
                          _c("br"),
                          _vm._v("pour ce produit")
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6 mb-4" }, [
                    _c(
                      "button",
                      {
                        staticClass: "cart-delivery rounded p-4 h-100",
                        class: { selected: _vm.delivery_mode === "drive" },
                        on: {
                          click: function($event) {
                            return _vm.selectDelivery("drive")
                          }
                        }
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center text-secondary font-weight-bold mb-3"
                          },
                          [
                            _vm._v(
                              "\n                Retrait au drive\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center text-muted" }, [
                          _vm._v(
                            "\n                Possible 3 heures après votre commande et à retirer sous 5 jours\n              "
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "drive" } }, [
                  _vm.delivery_mode === "drive"
                    ? _c("div", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [
                            _vm._v("Choissisez le lieu de votre retrait")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedCityDrive,
                                  expression: "selectedCityDrive"
                                }
                              ],
                              staticClass: "custom-select",
                              on: {
                                click: function($event) {
                                  return _vm.selectDrive()
                                },
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedCityDrive = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "agen" } }, [
                                _vm._v("Drive Agen")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "bordeaux" } }, [
                                _vm._v("Drive Bordeaux")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-5 bg-info rounded" }, [
                          _vm.selectedCityDrive === "agen"
                            ? _c("div", { staticClass: "row" }, [
                                _vm._m(3),
                                _vm._v(" "),
                                _vm._m(4)
                              ])
                            : _c("div", { staticClass: "row" }, [
                                _vm._m(5),
                                _vm._v(" "),
                                _vm._m(6)
                              ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.product.id == 2 &&
                  _vm.delivery_mode == "drive" &&
                  _vm.selectedCityDrive == "bordeaux"
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._m(7)
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "home" } }, [
                  _vm.delivery_mode === "home"
                    ? _c("div", [
                        !_vm.auth
                          ? _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      "\n                Mon domicile est-il livrable ?\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("auto-complete", {
                                  attrs: { items: _vm.city },
                                  on: {
                                    "get-search": _vm.delivery_check,
                                    "city-selected": _vm.selectCity
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h6", { staticClass: "text-secondary" }, [
                          _vm._v(
                            "\n              Choix de la ville\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.auth
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.user.adress1) +
                                  ","
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.user.zipcode) +
                                  " " +
                                  _vm._s(_vm._f("capitalize")(_vm.user.city)) +
                                  "\n            "
                              )
                            ])
                          : _c("div", [
                              _vm.searchCity.length > 0 &&
                              _vm.cityMessage === ""
                                ? _c("p", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                Veuillez nous consulter au 05 53 47 24 24\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.searchCity.length > 0 && _vm.delivery_check
                                ? _c("p", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.cityMessage) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                        _vm._v(" "),
                        _vm._m(8)
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.customerType !== "pro" &&
    _vm.categorySelected !== "pechavyHouse" &&
    _vm.delivery_mode === "home"
      ? _c("div", { staticClass: "card cart-step" }, [
          _c(
            "div",
            { staticClass: "card-header bg-white", attrs: { id: "step-4" } },
            [
              _c(
                "small",
                {
                  staticClass: "text-primary font-weight-bold",
                  class: { "text-muted": !_vm.packaging }
                },
                [_vm._v("\n        Etape 4\n      ")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "mb-0" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-link btn-block text-left text-secondary px-0",
                    attrs: {
                      type: "button",
                      "data-toggle": "collapse",
                      "data-target": "#step-content-4",
                      "aria-expanded": "true",
                      "aria-controls": "step-content-4",
                      disabled: !_vm.packaging
                    }
                  },
                  [
                    _vm.product.category !== 4
                      ? _c("div", [
                          _vm._v(
                            "\n          Je souhaite la dépalettisation\n            "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.category === 4
                      ? _c("div", [
                          _vm._v(
                            "\n          Je souhaite que l'on range mon bois\n            "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse",
              attrs: {
                id: "step-content-4",
                "aria-labelledby": "step-4",
                "data-parent": "#steps"
              }
            },
            [
              _vm.packaging === "palett"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.products, function(option, index) {
                        return option.category === 6
                          ? _c("div", { key: index, staticClass: "col-12" }, [
                              option.id === 17 && _vm.product.category === 4
                                ? _c(
                                    "article",
                                    {
                                      staticClass:
                                        "cart-option p-4 m-4 rounded bg-light",
                                      class: {
                                        "selected bg-white": option.selected
                                      },
                                      attrs: {
                                        itemprop: "itemListElement",
                                        itemscope: "",
                                        itemtype: "http://schema.org/Product"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.option_selected(option)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c(
                                            "h6",
                                            {
                                              staticClass: "option-name",
                                              attrs: { itemprop: "name" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(option.title) +
                                                  "\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "option-price font-weight-bold text-primary mb-4",
                                              attrs: {
                                                itemprop: "offers",
                                                itemscope: "",
                                                itemtype:
                                                  "http://schema.org/Offer"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { itemprop: "price" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm._f("roundPrice")(
                                                          option.price
                                                        )
                                                      ) +
                                                      " €\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.product.category == 4
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        "\n                          par stère\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(option.description) +
                                                "\n                      "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-5" }, [
                                          option.thumbnailImage
                                            ? _c("img", {
                                                staticClass: "rounded mb-4",
                                                attrs: {
                                                  itemprop: "image",
                                                  src:
                                                    "/static/" +
                                                    option.thumbnailImage,
                                                  width: "100%"
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "rounded mb-4",
                                                attrs: {
                                                  itemprop: "image",
                                                  src:
                                                    "/static/img/products/1.png",
                                                  width: "100%"
                                                }
                                              })
                                        ])
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              option.id !== 17 && _vm.product.category !== 4
                                ? _c(
                                    "article",
                                    {
                                      staticClass:
                                        "cart-option p-4 m-4 rounded bg-light",
                                      class: {
                                        "selected bg-white": option.selected
                                      },
                                      attrs: {
                                        itemprop: "itemListElement",
                                        itemscope: "",
                                        itemtype: "http://schema.org/Product"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.option_selected(option)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col-md-7" }, [
                                          _c(
                                            "h6",
                                            {
                                              staticClass: "option-name",
                                              attrs: { itemprop: "name" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(option.title) +
                                                  "\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "option-price font-weight-bold text-primary mb-4",
                                              attrs: {
                                                itemprop: "offers",
                                                itemscope: "",
                                                itemtype:
                                                  "http://schema.org/Offer"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { itemprop: "price" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm._f("roundPrice")(
                                                          option.price
                                                        )
                                                      ) +
                                                      " €\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.product.category !== 4
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ml-2" },
                                                    [
                                                      _vm._v(
                                                        "\n                          par palette\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(option.description) +
                                                "\n                      "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-md-5" }, [
                                          option.thumbnailImage
                                            ? _c("img", {
                                                staticClass: "rounded mb-4",
                                                attrs: {
                                                  itemprop: "image",
                                                  src:
                                                    "/static/" +
                                                    option.thumbnailImage,
                                                  width: "100%"
                                                }
                                              })
                                            : _c("img", {
                                                staticClass: "rounded mb-4",
                                                attrs: {
                                                  itemprop: "image",
                                                  src:
                                                    "/static/img/products/1.png",
                                                  width: "100%"
                                                }
                                              })
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn-outline-secondary ml-1"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Je souhaite la dépalettisation\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.customerType !== "pro" && _vm.categorySelected !== "pechavyHouse"
      ? _c("div", { staticClass: "card cart-step" }, [
          _c(
            "div",
            { staticClass: "card-header bg-white", attrs: { id: "step-6" } },
            [
              _c(
                "small",
                {
                  staticClass: "text-primary font-weight-bold",
                  class: { "text-muted": !_vm.packaging }
                },
                [_vm._v("\n        Dernière étape\n      ")]
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "mb-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.scrollToShoppingCart()
                      }
                    }
                  },
                  [_vm._v("Je finalise ma commande")]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse",
              attrs: {
                id: "step-content-6",
                "aria-labelledby": "step-6",
                "data-parent": "#steps"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.scrollToShoppingCart()
                    }
                  }
                },
                [_vm._v("Je finalise ma commande")]
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        {
          staticClass:
            "alert alert-info text-secondary d-flex align-items-center justify-content-center border-0 p-4 rounded-0",
          attrs: { role: "alert" }
        },
        [
          _c("img", {
            attrs: {
              src: "/static/dist/images/products/recycling.png",
              width: "31px"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "ml-3" }, [
            _c("b", [_vm._v("Gardez vos sacs, nous les recyclons")]),
            _vm._v(
              ". Nous récupérons les sacs vides lors d’une livraison future ou vous les ramenez lors d’un retrait du drive.\n              "
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mb-3" }, [
      _c("img", {
        attrs: { src: "/static/dist/images/delivery/home.png", height: "96px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mb-3" }, [
      _c("img", {
        attrs: { src: "/static/dist/images/delivery/drive.png", height: "96px" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "h6" }, [
        _vm._v("\n                    Adresse\n                  ")
      ]),
      _vm._v(" "),
      _c("address", [
        _vm._v("\n                    612, avenue du Brulhois"),
        _c("br"),
        _vm._v("\n                    ZI le Treil"),
        _c("br"),
        _vm._v("\n                    47520 Le passage\n                  ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "h6" }, [
        _vm._v(
          "\n                    Horaires d'ouvertures\n                  "
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v("\n                    Lundi, mercredi et vendredi"),
        _c("br"),
        _vm._v("\n                    08:15 - 12:00   13:45 - 18:00"),
        _c("br"),
        _vm._v("\n                    Mardi, jeudi et samedi"),
        _c("br"),
        _vm._v("\n                    08:15 - 12:00\n                    "),
        _c("span", [_vm._v("Fermé dimanche")]),
        _vm._v(" "),
        _c("b", [
          _vm._v(
            "Fermeture du Drive du 14 au 20 août ainsi que tous les samedis du 15 juillet au 26 août."
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "h6" }, [
        _vm._v("\n                    Adresse\n                  ")
      ]),
      _vm._v(" "),
      _c("address", [
        _vm._v("\n                      ZI des 2 Esteys"),
        _c("br"),
        _vm._v("\n                      Nouvelle route d'Ambés"),
        _c("br"),
        _vm._v("\n                      33530 Bassens\n                  ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "h6" }, [
        _vm._v(
          "\n                    Horaires d'ouvertures\n                  "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n                    Du lundi au vendredi"),
        _c("br"),
        _vm._v("\n                    08:30 - 12:00 | 14:00 - 17:00"),
        _c("br"),
        _vm._v(" "),
        _c("span", [_vm._v("Fermé samedi et dimanche")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v(
        "\n        Les granulés de bois GRASSASA ne sont pas disponibles au drive de Bordeaux, veuillez changer de granulés de bois (étape 2) ou opter pour le retrait au drive d'Agen "
      ),
      _c("br"),
      _vm._v("(étape 4)\n      ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c(
        "div",
        {
          staticClass:
            "alert alert-info text-secondary d-flex align-items-center justify-content-center border-0 p-4 rounded-0",
          attrs: { role: "alert" }
        },
        [
          _c("img", {
            attrs: { src: "/static/dist/images/icons/info.png", width: "21px" }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "ml-3" }, [
            _vm._v(
              "\n                Pour toute livraison à domicile, nous vous contacterons par téléphone pour convenir d’une date.\n              "
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }